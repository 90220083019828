$message-max-width: 100ch;

.prompt-area {
  margin: 0 auto;
  max-width: $message-max-width;
}

app-personal-rag {
  max-width: $message-max-width;
}

.input-chat {
  width: 100%;
  max-width: $message-max-width;
  margin: 0 auto;
}
